import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Loop Web Portal",
  "featuredImage": "images/portal-loop-19.png",
  "coverAlt": "Loop Web Portal",
  "description": "Lead Front End Development + UX for Loop Insight's Data Portal connected to their IOT devices.",
  "date": "2018-03-14",
  "dateModified": "2018-03-14",
  "category": "portfolio",
  "pinned": false,
  "featured": false,
  "tags": ["Front End Development", "UI", "UX"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {
          "margin": "2rem 0",
          "padding": "0"
        }
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1024px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/552482bfe3a31b508c2605778f3e715b/42a19/portal-loop-19.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "46.944444444444436%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAAsTAAALEwEAmpwYAAABv0lEQVQoz0VQ7W7TQBD0O+BWCv2TF0DwmgiQeIiCBAhEG0CIJDR2BE4u2IkTkKiQWpImDbjK2U7Ot7f3CQ4/GM2P1a5mRrNes9nsdrpJkpAhGUSDmp+j4WAYBuGT4+MgCAkZETKM41EcjwgZEBKNvpCwH4yT2Ltz996Hdqfd7gZhfzKZTMbpNJ1Np7NpOv06myXJOIqis17/pNV7fXrWehN87ETt972w9+n7+bnXaDSy31meFzvGnHPWGvcf9Wys21yv4lfP4pfPyYung9O3pJ9la9BGeb7vzy8vsvWKcy6EKMuCQ1XxqgavAIADri6+pa0Hycn9H+8eqnX6z5hS6vn+wXK5pDSXUiql8qLYUFrklFLKWIVSCVad/7p+PBs9SkmSUeccotRa50Xh3T46ym5unHNaa6200hpEDSmV1loIVEpLiSB2AplGwPqIUso6+eDw8Od8UXHgHIpyW5ZbIZBzAMDtjlGaAwjOa1Zc5EVJ84KxinOxodS75fvzxRUXCHtDZazV1lTCWSsRGWNbaYzSpgJrzL6IdIBWKkTcP2xxVXdDyRGFMtYYw4U1RiJyAKb24nqjOYCUytZi+Vf8BxIc0uAoE/MSAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Loop Insights Portal",
              "title": "Loop Insights Portal",
              "src": "/static/552482bfe3a31b508c2605778f3e715b/42a19/portal-loop-19.png",
              "srcSet": ["/static/552482bfe3a31b508c2605778f3e715b/53918/portal-loop-19.png 360w", "/static/552482bfe3a31b508c2605778f3e715b/242a6/portal-loop-19.png 720w", "/static/552482bfe3a31b508c2605778f3e715b/42a19/portal-loop-19.png 1024w"],
              "sizes": "(max-width: 1024px) 100vw, 1024px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Loop Insights Portal`}</figcaption>{`
  `}</figure></p>
    <p>{`Rebuilt Web portal in React (as the only Front end developer on the team).`}<br parentName="p"></br>{`
`}{`Portal’s purpose is to view `}<strong parentName="p">{`data visualizations`}</strong>{`, manage organizations, devices, etc. for users/clients. Was key part of UI/UX design decisions made in portal.`}</p>
    <p><strong parentName="p">{`Key features`}</strong>{`: User management (add, remove, delete users, reset passwords, etc.), organization management, creating reusable components using atomic design principles, creation of multiple data visualization components, testing components (E2E, unit and integrated tests), debugging cross-browser compatibility issues, digesting various APIs to populate Promotion builder, device management, etc.`}</p>
    <p>{`Website: `}<strong parentName="p"><a parentName="strong" {...{
          "href": "https://portal.loopinsights.ai/"
        }}>{`https://portal.loopinsights.ai`}</a></strong>{` - It is fully responsive and mobile first. It looks great on any device.`}</p>
    <h2 {...{
      "id": "skills--technologies-used",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#skills--technologies-used",
        "aria-label": "skills  technologies used permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Skills & Technologies Used`}</h2>
    <p>{`React(ES6), Node, HTML5, CSS/SASS, Data visualization (using Chart Js), Serverless, AWS Cognito, AWS Lambda, AWS QuickSight (and other AWS console products), Testing (using Cypress), SQL, Gitlab, JIRA, Zentao, npm, yarn, MapBox, CSS animations, React-select, React-dates, etc.`}</p>
    <h2 {...{
      "id": "multi-device-screenshots",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#multi-device-screenshots",
        "aria-label": "multi device screenshots permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Multi-device Screenshots`}</h2>
    <h2 {...{
      "id": "",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#",
        "aria-label": " permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <h2 {...{
      "id": "web-app-screenshots",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#web-app-screenshots",
        "aria-label": "web app screenshots permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Web app Screenshots`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "images/screenshot-demo.loopinsights.ai-2020.01.29-11_16_11-300x141.png",
              "alt": "Loop Insights Portal screenshot"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "images/screenshot-demo.loopinsights.ai-2020.01.29-11_12_56-300x141.png",
              "alt": "Loop Insights Portal screenshot"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "images/screenshot-aquaman.loopinsights.ai-2020.01.29-11_36_36-300x141.png",
              "alt": "Loop Insights Portal screenshot"
            }}></img></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "images/screenshot-demo.loopinsights.ai-2020.01.29-10_56_51-300x141.png",
              "alt": "Loop Insights Portal screenshot"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "images/screenshot-demo.loopinsights.ai-2020.01.29-11_14_19-300x141.png",
              "alt": "Loop Insights Portal screenshot"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "images/screenshot-portal.loopinsights.ai-2020.01.29-11_26_14-300x141.png",
              "alt": "Loop Insights Portal screenshot"
            }}></img></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "images/screenshot-portal.loopinsights.ai-2020.01.29-11_28_40-300x132.png",
              "alt": "Loop Insights Portal screenshot"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "images/screenshot-portal.loopinsights.ai-2020.01.29-11_31_18-300x132.png",
              "alt": "Loop Insights Portal screenshot"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "images/screenshot-portal.loopinsights.ai-2020.01.29-11_33_28-300x141.png",
              "alt": "Loop Insights Portal screenshot"
            }}></img></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "images/screenshot-demo.loopinsights.ai-2020.01.29-15_16_28-300x141.png",
              "alt": "Loop Insights Portal screenshot"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "images/screenshot-demo.loopinsights.ai-2020.01.29-15_18_05-300x169.png",
              "alt": "Loop Insights Portal screenshot"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "images/screenshot-demo.loopinsights.ai-2020.01.29-15_18_57-300x159.png",
              "alt": "Loop Insights Portal screenshot"
            }}></img></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "images/screenshot-aquaman.loopinsights.ai-2020.01.29-11_38_36-300x141.png",
              "alt": "Loop Insights Portal screenshot"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "images/screenshot-aquaman.loopinsights.ai-2020.01.29-11_39_08-300x141.png",
              "alt": "Loop Insights Portal screenshot"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "images/screenshot-portal.loopinsights.ai-2020.01.29-11_27_28-300x141.png",
              "alt": "Loop Insights Portal screenshot"
            }}></img></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "images/screenshot-portal.loopinsights.ai-2020.01.29-11_18_11-300x141.png",
              "alt": "Loop Insights Portal screenshot"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "images/screenshot-portal.loopinsights.ai-2020.01.29-11_21_15-300x141.png",
              "alt": "Loop Insights Portal screenshot"
            }}></img></td>
          <td parentName="tr" {...{
            "align": null
          }}><img parentName="td" {...{
              "src": "images/screenshot-portal.loopinsights.ai-2020.01.29-11_24_47-300x141.png",
              "alt": "Loop Insights Portal screenshot"
            }}></img></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      